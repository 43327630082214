<template>
    <div class="d-flex flex-grow-1">

        <v-main class="align-center ma-auto w-full" style="max-width: 480px">
            <v-card :loading="isLoading">
                <v-card-title>
                    <div class="text-center w-full">
                        {{ $t('views.passport.loggedOut.title') }}
                    </div>
                </v-card-title>
                <template v-if="vm">
                    <v-card-text v-if="vm.postLogoutRedirectUri">
                        <div v-html="$t('views.passport.loggedOut.redirect', { clientName: vm.clientName, url: vm.postLogoutRedirectUri })"></div>
                    </v-card-text>
                </template>
            </v-card>

            <iframe ref="iframe" class="iframe" v-if="vm && vm.signOutIframeUrl" width="0" height="0" :src="vm.signOutIframeUrl" @load="onIframeLoad" />
        </v-main>
    </div>
</template>

<style scoped>
    .iframe { display: none; }
</style>

<script>
import { getLoggedOutViewModel } from '@/api/passport';
import utils from '@/utils';

export default {
    data () {
        return {
            isLoading: false,
            isSubmitting: false,

            vm: null
        };
    },
    methods: {
        onIframeLoad () {
            if (this.vm.automaticRedirectAfterSignOut) {
                this.redirectSignOut();
            }
        },
        redirectSignOut () {
            var postLogoutRedirectUri = this.vm ? this.vm.postLogoutRedirectUri : null;
            window.location = postLogoutRedirectUri || '/';
        }
    },
    async created () {
        this.isLoading = true;
        var logoutId = utils.route.getQueryString(this.$route, 'logoutId');
        this.vm = await getLoggedOutViewModel({ logoutId });
        if (!this.vm.signOutIframeUrl) {
            this.redirectSignOut();
        }
        this.isLoading = false;
    }
};
</script>
